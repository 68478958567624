<template>
  <div id="specializedServices">
    <CommonTitle>
      <template v-slot:title>专项服务</template>
      <template v-slot:number>{{ number }}</template>
    </CommonTitle>
    <div class="item_list">
      <div class="item" v-for="item in dataList" :key="item.name" @click="drawer = true; link = item.link">
        <span><img :src="baseUrl + item.coverUrl" /></span>
        <div>
          <h3>{{ item.name }}</h3>
          <p class="tip">{{ item.summary }}</p>
          <p class="date">{{ item.publishedDate.split(' ')[0] }}</p>
        </div>
      </div>
    </div>
    <el-divider />
    <div class="realation">
      <div class="realation_left">
        <h3><svg-icon icon-class="contacts"></svg-icon> 联系我们</h3>
        <div>
          <p v-for="(item, index) in realList" :key="index">
            <svg-icon :icon-class="item.icon"></svg-icon>
            <span>{{ item.title }}：</span>
            <span>{{ detail[item.key] }}</span>
          </p>
        </div>
      </div>
      <div class="realation_right">
        <h3><svg-icon icon-class="message"></svg-icon> 在线留言区</h3>
        <p>如果有任何想对我们说的，或者遇到任何问题，都可以点击下方按钮给我们留言，我们会及时处理。</p>
        <el-button color="#0072EF" @click="drawer = true">留言给我们</el-button>
      </div>
    </div>
    <el-drawer
      v-model="drawer"
      :title="link ? '专项服务' : '在线留言区'"
      direction="btt"
      size="90%"
      :before-close="handleClose"
      :close-on-click-modal="false"
    >
      <iframe style="width:100%" id="iframe" :src="link || detail.leaveUrl"></iframe>
    </el-drawer>
  </div> 
</template>

<script>
import CommonTitle from '@/components/CommonTitle.vue'
export default {
  components: {
    CommonTitle
  },
  data() {
    return {
      number: 0,
      value: '',
      detail: {},
      dataList: [],
      realList: [
        { icon: 'address', title: '所在地址', key: 'address' },
        { icon: 'zip-code', title: '邮政编码', key: 'postCode' },
        { icon: 'time', title: '办公时间', key: 'workTime' },
        { icon: 'phone', title: '联系电话', key: 'workPhone' },
      ],
      drawer: false,
      link: null
    }
  },
  mounted() {
    this.showPage()
  },
  methods: {
    handleClose() {
      this.drawer = false;
      this.link = null;
    },
    showPage() {
      this.$API.SpecializedServicesList().then(res => {
        if(res.data.code == 200) {
          this.dataList = res.data.data.list;
          this.number = res.data.data.total;
        }
      })
      this.$API.Relation().then(res => {
        if(res.data.code == 200) {
          this.detail = res.data.data;
        }
      })
    },
  }
}
</script>

<style lang='scss'>
  #specializedServices {
    width: 1200px;
    padding: 30px 10px;
    margin: 0 auto;

    .top {
      margin-bottom: 20px;
      display: flex;
      align-items: center;

      & > h3 {
        line-height: 28px;
        font-size: 20px;
        color: #303133;
        margin-right: 10px;
      }

      & > span {
        width: 40px;
        height: 24px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 4px;
        line-height: 24px;
        font-size: 14px;
        text-align: center;
        color: #4e5969;
        display: block;
      }
    }

    .item_list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 280px;
        height: 300px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        margin-right: 20px;
        margin-bottom: 20px;
        overflow: hidden;
        cursor: pointer;

        &:nth-child(4n) {
          margin-right: 0;
        }
 
        &:hover {
          box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.05); 
        }

        & > span {
          display: block;
          width: 280px;
          height: 140px;
          overflow: hidden;
          // position: relative;

          & > img {
            width: 280px;
            // position: absolute;
            // top: 50%;
            // left: 50%;
            // transform: translate(-50%, -50%);
          }
        }

        & > div {
          padding: 20px;

          & > h3 {
            font-size: 18px;
            color: #303133;
            line-height: 25px;
            margin-bottom: 10px;
          }

          .tip {
            font-size: 14px;
            color: #606266;
            line-height: 24px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            // min-height: 48px;
          }

          .date {
            margin-top: 20px;
            font-size: 12px;
            color: #909399;
            line-height: 18px;
          }
        }
      }
    }

    .el-divider--horizontal {
      border-color: #ebebeb; 
      margin: 30px 0 50px;
    }

    .realation {
      display: flex;

      h3 {
        line-height: 22px;
        font-size: 16px;
        color: #303133;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .svg-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }

      .realation_left {
        width: 880px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        border-radius: 10px;
        padding: 21px 30px;
        margin-right: 20px;

        & > div {
          margin-top: 20px;

          & > p {
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            .svg-icon {
              width: 14px;
              height: 14px;
              margin-right: 10px;
              color: #000;
            }

            & > span {
              font-size: 14px;
              color: #303133;
              margin-right: 10px;
            }
          }
        }
      }

      .realation_right {
        width: 280px;
        height: 200px;
        background: #ffffff;
        border: 1px solid #ebebeb;
        padding: 21px 20px;
        border-radius: 10px;

        & > p {
          font-size: 14px;
          color: #909399;
          line-height: 24px;
          margin-bottom: 16px;
        }

        .el-button {
          width: 240px;
          height: 40px;
          border-radius: 4px;
        }
      }
    }
  }
</style>